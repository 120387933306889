import apiService from "../utils/apiService";

export const getAllLoans = (params) => {
    const queryString = new URLSearchParams(params).toString();
    return apiService.get(`/api/v1/user/get_loan_details?${queryString}`);
  };

  export const loanStatusUpdate=(fileNo, paylaod)=>apiService.post(`/api/v1/${fileNo}/update_loan_status`, paylaod);

  export const getLoanStatus=()=>apiService.get(`/api/v1/admin/loan-type`);
  export const getLoanDocInfo=(fileNo)=>apiService.get(`/api/v1/user/get_loan_details/${fileNo}/documents`);// this url move in Rm api
