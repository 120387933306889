import React from "react";
import { Chart as ChartJS, ArcElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement } from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { Table, Tag } from "antd";


// Register Chart.js components
ChartJS.register(ArcElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement);

const Dashboard = () => {
  // Chart Data for Line Chart
  const lineChartData = {
    labels: Array.from({ length: 30 }, (_, i) => i + 1),
    datasets: [
      {
        label: "Income",
        data: [3000, 2000, 3500, 4000, 2500, 4500, 3000],
        borderColor: "blue",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Outcome",
        data: [2000, 2500, 3000, 2000, 3000, 4000, 2500],
        borderColor: "red",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  // Chart Data for Pie Chart
  const pieChartData = {
    labels: ["Shopping", "Workspace", "Food", "Entertainment"],
    datasets: [
      {
        data: [300, 200, 100, 400],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };

  // Transactions Table Data
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Completed" ? "green" : "red"}>{status}</Tag>
      ),
    },
  ];

  const transactionsData = [
    {
      key: "1",
      name: "Cameron Williamson",
      date: "12/02/22",
      amount: "$17.12",
      status: "Pending",
    },
    {
      key: "2",
      name: "Courtney Henry",
      date: "11/02/22",
      amount: "$10.21",
      status: "Completed",
    },
    {
      key: "3",
      name: "Eleanor Pena",
      date: "10/02/22",
      amount: "$12.18",
      status: "Completed",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Top Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500">Balance</h4>
          <h2 className="text-2xl font-bold mt-2">$2190.19</h2>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500">Income</h4>
          <h2 className="text-2xl font-bold mt-2">$21.30</h2>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500">Savings</h4>
          <h2 className="text-2xl font-bold mt-2">$1875.10</h2>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500">Expenses</h4>
          <h2 className="text-2xl font-bold mt-2">$19.112</h2>
        </div>
      </div>

      {/* Finance and Expense Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
        <div className="lg:col-span-2 bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500 mb-4">Finances</h4>
          <Line data={lineChartData} />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500 mb-4">All Expenses</h4>
          <Pie data={pieChartData} />
        </div>
      </div>

      {/* Transactions Table and Quick Transfer */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2 bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500 mb-4">Transactions</h4>
          <Table columns={columns} dataSource={transactionsData} pagination={false} />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-gray-500 mb-4">Quick Transfer</h4>
          <input
            type="text"
            placeholder="Card Number"
            className="w-full p-2 border border-gray-300 rounded-lg mb-4"
          />
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full">
            Send Money
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
