
import Loigin from './pages/auth/Login';
import RootLayout from './layouts/RootLayout'; 
import Dashboard from './pages/Dashboard';
import AllLoans from './pages/AllLoans';
import LoanInfo from './pages/LoanInfo';




 const routes = [


 
  {
    path: '/',
    element: Loigin,
    layout: (props) => <RootLayout {...props} showSidebar={false} showNavbar={false} />,
    protected: false,
    name: 'Relationship Manager Login',
    loginType: 'RelationshipManager',
  },


  {
    path:"/dashboard",
    element: Dashboard,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },
  {
    path:"/loan",
    element: AllLoans,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  },
  {
    path:"/loan/loaninfo/:fileNo",
    element: LoanInfo,
    layout: RootLayout,
    protected: true,
    name:"RM Dashboard",
    allowedRoles: ['RM'],
  }


 



  

  







 





 



 












 
 



];

export default routes;
